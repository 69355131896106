import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

/**
 * Interfaz de los accesorios del componente
 */
interface IMultiActionAreaCardProps {
    /**
     * Título de la tarjeta
     */
    title?: string;
    /**
     * Descripción de la tarjeta
     */
    text?: string;
    /**
     * Url de la imagen
     */
    src?: string
    /**
     * Botones de la tarjeta
     */
    buttons?: {
        title?: string,
        value: any
    }[] | string[];
    /**
     * Manejador de evento al clickear un boton
     * @param value valor del botón accionado
     */
    onClickButton?(value: any): void;
}

/**
 * Definir componente Card
 * @param props Accesorios del componente
 * @returns Componente renderizado
 */
export default function CardComponent(props: IMultiActionAreaCardProps) {
  /**
   * Propiedades de la etiqueta Imagen
   */
  let propsMedia = {};

  if(props.src) {
    // Asignar atributos de la etiqueta Imagen
    propsMedia = {
      height: "140",
      image: props.src,
      alt: props.title
    };
  }
  
  // Renderizar componente
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia component="img" {...propsMedia} />
        <CardContent>
          {props.title && (
            <Typography gutterBottom variant="h5" component="div">
                {props.title}
            </Typography>
          )}
          {props.text && (
            <Typography variant="body2" color="text.secondary">
                {props.text}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {props.buttons && !!props.buttons.length && (
        <CardActions>
            {props.buttons.map(buttonItem => {
              if(typeof buttonItem === "string") {
                return (
                  <Button 
                    key={buttonItem} 
                    onClick={props.onClickButton && (() => props.onClickButton!(buttonItem))}
                    size="small" 
                    color="primary"
                  >
                    {buttonItem}
                  </Button>
                )
              }
              return (
                <Button 
                    key={String(buttonItem.title) + String(buttonItem.value)} 
                    onClick={props.onClickButton && (() => props.onClickButton!(buttonItem.value))}
                    size="small" 
                    color="primary"
                >
                    {buttonItem.title ?? String(buttonItem.value)}
                </Button>
              )
            })}
        </CardActions>
      )}
    </Card>
  );
}