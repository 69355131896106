import React from "react";
import { IClientBot } from "../../../models/whatsapp_venta";
import axios from "axios";
import urlJoin from "url-join";
import environments from "../../../environments/environments";
import CardComponent from "../../../components/card.component";
import { useNavigate } from "react-router-dom";
import "./index.css";
import ButtonAppBar from "../../../components/app.component";

/**
 * Manejar una lista de clientes
 * @returns página renderizada
 */
export default function ManageClientsApp() {
    /** Lista de clientes */
    const [clients, setClients] = React.useState<IClientBot[]>([]);
    /** Nro de intento para obtener (refrescar) la lista de los clientes */
    const [nGetClients, setNGetClients] = React.useState(0);

    const navigate = useNavigate();
    
    /**
     * Refrescar lista de clientes
     */
    function refreshClients() {
        setNGetClients(nGetClients + 1);
    }
    
    /**
     * Efecto al inicializar el componente
     */
    React.useEffect(() => {
        const abort = new AbortController();
        
        axios.get(urlJoin(environments.server, "clients"), { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                if(res.status == 200) {
                    setClients(res.data);
                }
                else throw new Error("No se logró obtener los clientes");
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
                // Reintentar luego de 4s
                setTimeout(refreshClients, 4000);
            });
        
        return () => abort.abort();
    }, []);
    
    /** Renderizar componente */
    return (
        <>
            <ButtonAppBar></ButtonAppBar>
            <div className="list-clients-container">
                {clients.map(clientItem => (
                    <div className="list-clients-container-item" key={String(clientItem.id_client)}>
                        <CardComponent
                            title={clientItem.name}
                            src={clientItem.picture}
                            buttons={["Administrar"]}
                            onClickButton={() => {
                                navigate("/client/" + clientItem.id_client);
                            }}
                        ></CardComponent>
                    </div>
                ))}
                {/* <CardComponent
                    title="Planes mensuales"
                    src="/planes.jpg"
                    buttons={["Administrar"]}
                    onClickButton={() => {
                        navigate("/config/planes");
                    }}
                ></CardComponent>
                <CardComponent
                    title="Historial"
                    src="/clock.jpg"
                    buttons={["Administrar"]}
                    onClickButton={() => {
                        navigate("/historys/");
                    }}
                ></CardComponent> */}
            </div>
        </>
    )
}