import React, { useMemo } from "react";
import { Table, TableRow, TableCell, TableBody, TableHead, IconButton, Grid, Box, TextField, CardHeader, Stack, Pagination, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";
import environments from "../../../environments/environments";
import urlJoin from "url-join";
import { RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ButtonAppBar from "../../../components/app.component";
import { useScroll } from "../../../hooks/useScroll.hook";

export default function ManagerHistorys() {
    const navigate = useNavigate();
    const [historys, setHistorys] = React.useState<{
        timestamp_finished: number,
        count_messages: number,
        dni: string | undefined,
        nro_referencia: string | undefined,
        nombre_usuario: string | undefined,
        url_contrato: string | undefined,
        identifier: string,
    }[]>([]);

    const [fNombre, setFNombre] = React.useState("");
    const [fCedula, setFCedula] = React.useState("");
    const [fPagado, setFPagado] = React.useState("");
    const [fContratoGenerado, setFContratoGenerado] = React.useState("");
    const [fNroRef, setFNroRef] = React.useState("");
    const [fCountMsg, setFCountMsg] = React.useState("");
    const [fFechaFinalizado, setFechaFinalizado] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(30);

    React.useEffect(() => {
        const abort = new AbortController();

        axios.get(urlJoin(environments.server, "historys"), { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                setHistorys(res.data);
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            })

        return () => abort.abort();
    }, [])

    // Filtrar
    const historyFiltered = useMemo(() => (
        historys.filter(h => (
            (!fNombre || h.nombre_usuario?.toLocaleLowerCase().includes(fNombre.toLocaleLowerCase())) && 
            (!fCedula || h.dni?.toLocaleLowerCase().includes(fCedula.toLocaleLowerCase())) && 
            (!fPagado || (h.nro_referencia ? "SI" : "NO").toLocaleLowerCase().includes(fPagado.toLocaleLowerCase())) && 
            (!fContratoGenerado || (h.url_contrato ? "SI" : "NO").toLocaleLowerCase().includes(fContratoGenerado.toLocaleLowerCase())) && 
            (!fNroRef || h.nro_referencia?.toLocaleLowerCase().includes(fNroRef.toLocaleLowerCase())) && 
            (!fCountMsg || String(h.count_messages).toLocaleLowerCase().includes(fCountMsg.toLocaleLowerCase())) &&
            (!fFechaFinalizado || String(new Date(h.timestamp_finished).toLocaleString()).toLocaleLowerCase().includes(fFechaFinalizado.toLocaleLowerCase()))
        ))
    ), [historys, fNombre, fCedula, fPagado, fContratoGenerado, fNroRef, fCountMsg, fFechaFinalizado]);

    const historysPaged = [...historyFiltered].slice(page*limit, page*limit+limit);
    const maxPage = Math.ceil(historyFiltered.length / limit);

    const scroll = useScroll();

    React.useEffect(() => {
        // document.body.scrollTo({top: 10000000});
        scroll.topScroll();
    }, [page]);
    
    return (
        <>
            <ButtonAppBar back={() => navigate(urlJoin("/"))}></ButtonAppBar>
            <Grid container>
                <Grid item xs={10}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{background: "gray"}}>
                                <TableCell sx={{color: "white"}}>Nombre</TableCell>
                                <TableCell sx={{color: "white"}}>Cédula</TableCell>
                                <TableCell sx={{color: "white"}}>Pagado</TableCell>
                                <TableCell sx={{color: "white"}}>Contrato Generado</TableCell>
                                <TableCell sx={{color: "white"}}>Nro. Ref</TableCell>
                                <TableCell sx={{color: "white"}}>Cantidad Mensajes</TableCell>
                                <TableCell sx={{color: "white"}} align="right">Fecha finalizado</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historysPaged.map(item => (
                                <TableRow>
                                    <TableCell>{item.nombre_usuario || "Anónimo"}</TableCell>
                                    <TableCell>{item.dni || ""}</TableCell>
                                    <TableCell>{item.nro_referencia ? "SI" : "NO"}</TableCell>
                                    <TableCell>{item.url_contrato ? "SI" : "NO"}</TableCell>
                                    <TableCell>{item.nro_referencia || ""}</TableCell>
                                    <TableCell>{item.count_messages}</TableCell>
                                    <TableCell align="right">{new Date(item.timestamp_finished).toLocaleString()}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => navigate(urlJoin(String(item.timestamp_finished), encodeURIComponent(item.identifier)))}>
                                            <RemoveRedEye></RemoveRedEye>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!!historysPaged.length && (
                        <Box display="flex" justifyContent="center" p={2}>
                            <Pagination 
                                page={page+1} 
                                count={maxPage} 
                                variant="outlined" 
                                color="primary"
                                onChange={(_, page) => {
                                    setPage(page-1);
                                }}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={2} sx={{boxShadow: "-1px 1px 1px 1px rgba(0,0,0,0.1)"}}>
                    <Box sx={{position: "sticky", top: "0"}}>
                        <CardHeader title="Filtrar"></CardHeader>
                        <Box p={1} display="flex" flexDirection="column" gap={1.5}>
                            <TextField
                                label="Nombre"
                                size="small"
                                onChange={(ev) => setFNombre(ev.target.value)}
                            ></TextField>
                            <TextField
                                label="Cédula"
                                size="small"
                                onChange={(ev) => setFCedula(ev.target.value)}
                                ></TextField>
                            <FormControl fullWidth size="small">
                                <InputLabel>Pagado</InputLabel>
                                <Select
                                    value={fPagado}
                                    label="Pagado"
                                    onChange={(e) => setFPagado(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="">Ninguno</MenuItem>
                                    <MenuItem value="SI">SI</MenuItem>
                                    <MenuItem value="NO">NO</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Contrato generado</InputLabel>
                                <Select
                                    value={fContratoGenerado}
                                    label="Contrato generado"
                                    onChange={(e) => setFContratoGenerado(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="">Ninguno</MenuItem>
                                    <MenuItem value="SI">SI</MenuItem>
                                    <MenuItem value="NO">NO</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Nro. Ref"
                                size="small"
                                onChange={(ev) => setFNroRef(ev.target.value)}
                                ></TextField>
                            <TextField
                                label="Candidad Mensajes"
                                size="small"
                                onChange={(ev) => setFCountMsg(ev.target.value)}
                                ></TextField>
                            <TextField
                                label="Fecha finalizado"
                                size="small"
                                onChange={(ev) => setFechaFinalizado(ev.target.value)}
                            ></TextField>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}