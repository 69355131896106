import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import ManageClientsApp from "./app/manager/clients";
import ManageChatsApp from "./app/manager/chats";
import ManageChatApp from "./app/manager/chat";
import ManagerHistorys from "./app/manager/historys";
import ManagerHistory from "./app/manager/history";
import PlanesApp from "./app/config/planes";
import PageLayout from "./layout/layout";
import FranquiciaApp from "./app/config/franquicias";
import PromocionesApp from "./app/config/promociones";
import LoginApp from "./app/login";
import AuthGuard from "./guard/auth.guard";

export const router = createBrowserRouter([
    {
        element: (
            <AuthGuard 
                authorized={<PageLayout/>} 
                unauthorized={<Navigate to="/login"/>}
            />
        ),
        children: [
            {
                path: "/",
                element: <ManageClientsApp></ManageClientsApp>
            },
            {
                path: "/historys",
                element: <ManagerHistorys></ManagerHistorys>
            },
            {
                path: "/historys/:timestamp/:identifier",
                element: <ManagerHistory></ManagerHistory>
            },
            {
                path: "/client/:id_client",
                element: <ManageChatsApp></ManageChatsApp>,
            },
            {
                path: "/client/:id_client/chat/:id_chat",
                element: <ManageChatApp></ManageChatApp>
            },
            {
                path: "/config/planes",
                element: <PlanesApp></PlanesApp>
            },
            {
                path: "/config/franquicias",
                element: <FranquiciaApp></FranquiciaApp>
            },
            {
                path: "/config/promociones",
                element: <PromocionesApp></PromocionesApp>
            },
        ]
    },
    {
        path: "login",
        element: <LoginApp></LoginApp>
    }
])