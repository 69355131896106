import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import urlJoin from "url-join";
import environments from "../../../environments/environments";
import axios from "axios";
import { IMessage } from "../../../models/whatsapp_venta";
import { CircularProgress } from "@mui/material";
import ChatComponent from "../../../components/chat.component";
import VarsComponent from "../../../components/vars.component";
import ButtonAppBar from "../../../components/app.component";

export default function ManagerHistory() {
    const navigate = useNavigate();
    const { timestamp, identifier } = useParams();
    const [history, setHistory] = React.useState<{messages: IMessage[], vars: {[key: string]: any}} | undefined>();

    React.useEffect(() => {
        const abort = new AbortController();

        axios.get(urlJoin(environments.server, "historys", encodeURIComponent(identifier || "")), { 
            params: {timestamp: Number(timestamp)}, 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                setHistory(res.data);
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            })
        
        return () => abort.abort();
    }, []);

    if(history) {
        return (
            <>
                <ButtonAppBar back={() => navigate(urlJoin("/historys"))}></ButtonAppBar>
                <div style={{display: "flex", top: "0", left: "0", width: "100%", minHeight: "100vh"}}>                
                    <ChatComponent messages={history.messages}></ChatComponent>
                    <VarsComponent vars={history.vars}></VarsComponent>
                </div>
            </>
        )
    }
    return <CircularProgress></CircularProgress>
}