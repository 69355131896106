import React from "react";
import { createPortal } from "react-dom";

/**
 * Definir componente Portal
 * @param props Accesorios
 * @returns Componente renderizado
 */
export default function PortalComponent(props: { children: React.ReactElement }) {
    // Renderizar componente
    return <>{createPortal(props.children, document.body)}</>;
}