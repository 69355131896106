import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, ListItemButton, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import environments from "../../environments/environments";
import Swal from "sweetalert2";
import ButtonAppBar from "../../components/app.component";
import { Link, useNavigate } from "react-router-dom";

export interface API_FRANQUICIA {
    id_franq: string,
    nombre_franq: string,
    id_emp: string,
    obser_franq: string,
    direccion_franq: string,
    serie:string,
    id_gf:string,
    ord: number,
    c_sistema: boolean,
    serie_p: string,
    serie_pago: string,
    serie_factura: string,
    iniciales: string,
    codigo_legal: string,
    codigo_franq: string,
    logo_franq: string,
    serie_nro_cp: null,
    id_operador:string
}

interface IFranquiciasProps {
    name?: string
    value?: string
    onChange?: (value: string) => void
}

function FranquiciasComponent({
    name,
    value, 
    onChange
}: IFranquiciasProps) {
    const [franquicias, setFranquicias] = React.useState<API_FRANQUICIA[]>([]);
    
    React.useEffect(() => {
        const abort = new AbortController();
        
        /**
         * Obtener todas las franquicias
         */
        axios.get<API_FRANQUICIA[]>(new URL("/planes/franquicia", environments.server).href, { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                if(res.status == 200) {
                    setFranquicias(res.data);
                }
                else {
                    throw new Error("Hubo un error al intentar obtener las franquicias");
                }
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            })
        
        return () => abort.abort();
    }, []);
    
    return (
        <FormControl fullWidth>
            <FormControl fullWidth>
            <InputLabel>Franquicias</InputLabel>
            <Select
                fullWidth
                name={name}
                value={value || ''}
                label="Franquicias"
                onChange={onChange && ((ev) => onChange(ev.target.value as string))}
            >
                {franquicias.map(franq => (
                    <MenuItem 
                        key={franq.id_franq} 
                        value={franq.id_franq}
                    >
                        {franq.nombre_franq}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        </FormControl>
    )
}

export interface API_PLANES {
	base_tarifa: number,
	iva_tarifa: number,
	total_tarifa: number,
	id_serv: string,
	id_tipo_servicio: string,
	nombre_servicio: string,
	obser_serv: string,
	tipo_serv: string,
	tipo_tarifa: string,
	abrev_servicio: string,
	id_iva: string,
	tipo_servicio: string,
	status_servicio: string,
	cantidad_megas: string,
	estatus: string,
	id_franq: string,
	nombre_franq: string,
	obser_franq: string,
	direccion_franq: string,
	tarifa_ser: string,
	p_iva: string,
	cantidad_mega_deducido: string,
    nombre_servicio_deducido: string
}

interface IPlanesProps {
    franquicia: string
    activates: string[],
    labels: {id_serv: string, label: string}[]
    onChangePlanes: (activates: string[]) => void
    onChangeLabels: (labels: {id_serv: string, label: string}[]) => void
}

function PlanesComponent({
    franquicia, 
    activates,
    labels,
    onChangePlanes,
    onChangeLabels
}: IPlanesProps) {
    const [planes, setPlanes] = React.useState<API_PLANES[]>([]);

    React.useEffect(() => {
        const abortController = new AbortController();

        // Permite descargar los planes
        if(franquicia) {
            Promise.all([
                // Obtener planes disponibles
                axios.get<API_PLANES[]>(new URL("/planes/franquicia/" + encodeURIComponent(franquicia), environments.server).href, { 
                    headers: {Authorization: localStorage.getItem("authtoken")},
                    signal: abortController.signal
                })
            ])
                .then(([planes]) => {
                    // setLabels(labels.data);
                    setPlanes(planes.data);
                })
                .catch((err) => {
                    if(!abortController.signal.aborted) {
                        console.error(err);
                    }
                });
    
            return () => abortController.abort();
        }
    }, [franquicia]);

    function toogleActivatePlan(id_serv: string) {
        onChangePlanes(activates.some(id_servItem => id_servItem === id_serv) ? activates.filter(item => item !== id_serv) : [...activates, id_serv]);
    }

    function changeLabelActivate(id_serv: string, label: string) {
        const newLabels = labels.filter(item => item.id_serv !== id_serv);
        onChangeLabels([...newLabels, {id_serv,label}]);
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{width: 0}}>Habilitar</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Nombre a mostrar</TableCell>
                    <TableCell>Precio</TableCell>
                    <TableCell>Estado</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {planes.map(plan => {
                    const isActivate = activates.some(id_serv => plan.id_serv === id_serv);

                    return (
                        <TableRow key={"FRANQ" + plan.id_serv}>
                            <TableCell>
                                <Tooltip title={isActivate ? "Deshabilitar" : "Habilitar"}>
                                    <Checkbox checked={!!isActivate} onClick={() => toogleActivatePlan(plan.id_serv)}/>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <TextField 
                                    placeholder={plan.nombre_servicio}
                                    size="small" 
                                    fullWidth
                                    value={labels.find(l => l.id_serv === plan.id_serv)?.label || ""}
                                    onChange={(ev) => changeLabelActivate(plan.id_serv, ev.target.value)}
                                ></TextField>
                            </TableCell>
                            <TableCell>{plan.nombre_servicio}</TableCell>
                            <TableCell>${plan.total_tarifa}</TableCell>
                            <TableCell>{plan.estatus}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}

export default function PlanesApp() {
    const navigate = useNavigate();
    const [franquicia, setFranquicia] = React.useState<string | undefined>();
    const [activates, setActivates] = React.useState<string[]>([]);
    const [isModified, setIsModified] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [labels, setLabels] = React.useState<{id_serv: string, label: string}[]>([]);

    async function saveActivates() {
        Swal.fire({
            title: "Guardando",
            text: "Estamos guardando los cambios",
            footer: "Espere unos momentos",
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        
        Promise.all([
            axios.put(new URL("/planes/labels", environments.server).href, {labels}, {
                headers: {Authorization: localStorage.getItem("authtoken")}
            }),
            axios.put(new URL("/planes/franquicia/" + encodeURIComponent(franquicia || "") + "/activates", environments.server).href, { activates }, { headers: {Authorization: localStorage.getItem("authtoken")} })
        ])
            .then(([res, res2]) => {
                if(res.status == 200 && res2.status == 200) {
                    setIsModified(false);
                    Swal.fire({
                        icon: "success",
                        title: "Correcto",
                        text: "Se ha actualizado la configuración",
                        footer: "Puedes cerrar este cuadro de diálogo"
                    });
                }
                else {
                    throw new Error("Hubo un error en la petición");
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se logró actualizar la configuración de los planes",
                    footer: `Motivo: ${err instanceof Error ? err.message : "Desconocido"}`
                });
            })
    }
    
    async function handlerChangeFranq(id_franq: string) {
        if(isModified) {
            const res = await Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "No has guardado los cambios. ¿Te gustaría guardar los cambios efectuados en la franquicia actual?",
                confirmButtonText: "Guardar",
                cancelButtonText: "No guardar",
                showCancelButton: true,
            });

            if(res.isConfirmed) {
                await saveActivates();
            }
            else {
                if(res.dismiss === Swal.DismissReason.backdrop) return;
            }
        }

        setIsLoading(true);
        setIsModified(false);
        setFranquicia(id_franq);

        Promise.all([
            axios.get<{id_serv: string, label: string}[]>(new URL("/planes/labels", environments.server).href, {
                headers: {Authorization: localStorage.getItem("authtoken")}
            }),
            axios.get(new URL("/planes/franquicia/" + encodeURIComponent(id_franq) + "/activates", environments.server).href, { headers: {Authorization: localStorage.getItem("authtoken")} })
        ])
            .then(([labels, res]) => {
                if(res.status == 200) {
                    setIsLoading(false);
                    setActivates(res.data);
                    setLabels(labels.data);
                }
                else throw new Error("Hubo un error durante la petición");
            })
            .catch((err) => {
                setIsLoading(false);
                setActivates([]);
                console.error(err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se logró obtener la configuración actual de los planes",
                    footer: `Motivo: ${err instanceof Error ? err.message : "Desconocido"}`
                });
            });
    }
    
    function handlerChangeActivates(activates: string[]) {
        setIsModified(true);
        setActivates(activates);
    }

    function handlerChangeLabels(labels: {id_serv: string, label: string}[]) {
        setIsModified(true);
        setLabels(labels);
    }

    let bodyChildren;

    if(!isLoading) {
        bodyChildren = (
            <Grid container p={2} spacing={1}>
                <Grid item xs={12}>
                    <Box p={2} my={1} sx={{background: "#eeeeee", borderRadius: "5px"}}>
                        <Typography sx={{color: "#444"}}>
                            Este apartado permite ayudar al sistema del bot a determinar los planes disponibles para cada franquicia.<br/><br/>
                            <b>NOTA:</b> Sólo se mostraran las franquicias que hayas habilidato en el apartado <Link to="/config/franquicias" >Ciudad franquicia</Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <FranquiciasComponent
                        name="franquicia"
                        value={franquicia}
                        onChange={handlerChangeFranq}
                    />
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <Button 
                        variant="contained" 
                        disabled={!isModified} 
                        onClick={saveActivates}
                        fullWidth
                    >
                        Guardar
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {franquicia ? (
                        <PlanesComponent 
                            franquicia={franquicia} 
                            activates={activates}
                            labels={labels}
                            onChangePlanes={handlerChangeActivates}
                            onChangeLabels={handlerChangeLabels}
                        />
                    ) : (
                        <Box p={2} sx={{backgroundColor: "#cff", color: "#006"}}>
                            Seleccione una franquicia para ver la lista de planes activos
                        </Box>
                    )}
                </Grid>
            </Grid>
        )
    }
    else {
        bodyChildren = (
            <Box display="flex" justifyContent="center">
                <CircularProgress></CircularProgress>
            </Box>
        );
    }
    return (
        <div>
            <ButtonAppBar back={() => navigate("../../")}></ButtonAppBar>
            {bodyChildren}
        </div>
    );
}