import { Backdrop, Box, CircularProgress } from "@mui/material";
import axios, { AxiosError } from "axios";
import React from "react";
import environments from "../environments/environments";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

/**
 * Interfaz del usuario
 */
interface IUser {
    id_user: number,
    username: string,
    email: string,
}

/**
 * Contexto del usuario
 */
export const userContext = React.createContext<IUser>({
    id_user: -1,
    username: "Anonymous",
    email: ""
});

/**
 * Accesorios
 */
interface AuthGuardProps {
    authorized: React.ReactElement,
    unauthorized: React.ReactElement
}

/**
 * Componente para validar si el usuario se encuentra logueado
 * @param props Accesorios
 */
export default function AuthGuard(props: AuthGuardProps) {
    const [user, setUser] = React.useState();
    const [status, setStatus] = React.useState<"checking" | "authorized" | "unauthorized">("checking");
    const navigate = useNavigate();

    React.useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => {
              return response;
            },
            (error) => {
              if(error instanceof AxiosError) {
                const url = error.config?.url;
                
                if(url && url.startsWith(environments.server)) {
                    if((error.status || error.response?.status) == 401) {

                        (async () => {
                            if(localStorage.getItem("authtoken")) {
                                await Swal.fire({
                                    icon: "info",
                                    title: "Usuario no autenticado",
                                    text: "Su sessión ha expirado, es necesario volver a iniciar sessión"
                                });
                                
                                localStorage.removeItem("authtoken");
                            }
                            
                            navigate("/login")
                        })();
                  }
                }
              }
          
              return Promise.reject(error);
            }
        );
        
        return () => axios.interceptors.request.eject(interceptor);
    })
    
    /**
     * Validar si el usuario está logueado
     */
    React.useEffect(() => {
        const abort = new AbortController();

        const loadUserData = () => {
            axios.get(new URL("/auth", environments.server).href, {
                headers: {Authorization: localStorage.getItem("authtoken")}, 
                signal: abort.signal
            })
                .then((res) => {
                    setStatus("authorized");
                    setUser(res.data);
                })
                .catch((err) => {
                    if(err instanceof AxiosError) {
                        if((err.status || err.response?.status) == 401) {
                            // Usuario no authorizado
                            setStatus("unauthorized");
                            return;
                        }
                    }
                    // Posiblemente tenga problemas de red... continuar reintentando
                    setTimeout(() => {
                        loadUserData();
                    }, 5000);
                })
        }

        // Cargar datos del servidor
        loadUserData();
        
        return () => abort.abort();
    }, []);
    
    switch(status) {
        case "authorized":
            if(user) {
                return (
                    <userContext.Provider value={user}>
                        {props.authorized}
                    </userContext.Provider>
                );
            }
            return props.unauthorized;

        case "unauthorized":
            return props.unauthorized;
    }

    return (
        <Backdrop open={true}>
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                <CircularProgress></CircularProgress>
            </Box>
        </Backdrop>
    )
}