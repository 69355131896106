import React from "react";
import { IMessage } from "../models/whatsapp_venta";
import { Box, Modal, Card, CardHeader, Divider, List, ListItemButton, Button, Radio } from "@mui/material";
import "./chat.component.css";
import ListIcon from '@mui/icons-material/List';

/**
 * Definir componente burbuja del mensaje
 * @param props Accesorios del componente
 * @returns Componente renderizado
 */
function ChatBubbleComponent(props: { message: IMessage }) {
    switch(props.message.type) {
        case "audio":
            return (
                <div>
                    {props.message.body}
                </div>
            );
            

        case "document":
            return (
                <div>{props.message.body}</div>
            );

        case "image":
            return (
                <>
                    <img src={props.message.media} className="img-message"></img>
                    <div>{props.message.body}</div>
                </>
            );

        case "location":
            return (
                <div>{props.message.body}</div>
            );

        case "text":
            return (
                <div>{props.message.body}</div>
            );

        case "video":
            return (
                <div>{props.message.body}</div>
            );
    }

    return null;
}

/**
 * Definir componente para mostrar un mensaje
 * @param props Accesorios del componente
 * @returns Componente renderizado
 */
function MessageRowComponent(props: { message: IMessage }) {
    const [options, setOptions] = React.useState<IMessage["options"] | undefined>();
    
    function onClose() {
        setOptions(undefined);
    }

    function onOpen() {
        if(props.message.options) {
            setOptions(props.message.options);
        }
    }
    
    return (
        <>
            <Box className={"message-row " + (props.message.from_me ? "from-me " : "")}>
                <Box className={"message-item"}>
                    <Box className={"message-bubble "}>
                        <ChatBubbleComponent message={props.message}></ChatBubbleComponent>
                    </Box>
                    <Box className="message-container-buttons">
                        {props.message.options && props.message.options.length <= 3 && props.message.options.map((option, index) => (
                            <button key={index} className="message-bubble message-button">{option}</button>
                        ))}
                        {props.message.options && props.message.options.length > 3 && (
                            <button className="message-bubble message-button message-button-list" onClick={onOpen}>
                                <ListIcon></ListIcon> Ver opciones
                            </button>
                        )}
                    </Box>
                </Box>
            </Box>
            {options && (
                <Modal open={true} onClose={onClose} className="modal-background">
                    <Card>
                        <Box p={1}>
                            <CardHeader title="Selecciona una opción"></CardHeader>
                            <Divider></Divider>
                            <List>
                                {options.map(optionItem => (
                                    <label>
                                        <ListItemButton key={optionItem} sx={{color: "dodgerblue"}}>
                                            <input type="radio" name="options"></input> &nbsp; {optionItem}
                                        </ListItemButton>
                                    </label>
                                ))}
                            </List>
                            <Button variant="contained" onClick={onClose} fullWidth>Cerrar</Button>
                        </Box>
                    </Card>
                </Modal>
            )}
        </>
    )
}

/**
 * Definir componente para mostrar un chat
 * @param props Accesorios del componente
 * @returns Componente renderizado
 */
export default function ChatComponent(props: { messages: IMessage[] }) {
    const ref = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        setTimeout(() => {
            if(ref.current) {
                ref.current.scrollTo({top: 10000000000, behavior: "smooth"})
            }
        }, 400);
    }, [props.messages.length])
    
    // Renderizar componente
    return (
        <div className="chat-container scroll-styled" ref={ref}>
            {props.messages.map(msgItem => (
                <MessageRowComponent key={msgItem.id} message={msgItem}></MessageRowComponent>
            ))}
        </div>
    )
}