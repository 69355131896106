import { Box, List, MenuItem, SvgIconTypeMap, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import HistoryIcon from '@mui/icons-material/History';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Home, Mail } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useRef } from "react";
import { scrollContext } from "../hooks/useScroll.hook";

export default function PageLayout() {
    const refContent = useRef<HTMLDivElement>();
    const pathMsgs = useRef<string | undefined>();
    const navigate = useNavigate();
    const location = useLocation();
    const { id_chat, identifier } = useParams();
    
    const optionsMenu: {
        icon: OverridableComponent<SvgIconTypeMap>,
        path: string,
        display: string,
        isActive?: boolean
    }[] = [
        {
            icon: Home,
            path: "/",
            display: "Inicio"
        },
        {
            icon: LocationCityIcon,
            path: "/config/franquicias",
            display: "Ciudad franquicia"
        },
        {
            icon: LocalGroceryStoreIcon,
            path: "/config/planes",
            display: "Planes Mensuales"
        },
        {
            icon: CurrencyExchangeIcon,
            path: "/config/promociones",
            display: "Promociones",
        },
        {
            icon: HistoryIcon,
            path: "/historys",
            display: "Historial"
        },
    ];

    if(id_chat || identifier) {
        pathMsgs.current = location.pathname;
    }

    if(pathMsgs.current) {
        optionsMenu.push({
            icon: Mail,
            path: pathMsgs.current,
            display: "Mensajería",
            isActive: !!(id_chat || identifier)
        });
    }

    function topScroll() {
        if(refContent.current) {
            refContent.current.scrollTo({top: 10000000});
        }
    }
    
    return (
        <div 
            style={{
                display: "flex",
                justifyContent: "stretch",
                height: "100%",
                width: "100%",
                position: "fixed",
            }}
        >
            <div style={{
                minWidth: "200px", 
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                paddingTop: "2em"
            }}>
                <List>
                    {optionsMenu.map(option => {
                        const color = (location.pathname == (option.path) || option.isActive) ? "primary" : undefined;

                        return (
                            <MenuItem 
                                key={option.path} 
                                onClick={() => navigate(option.path)}
                            >
                                <Box display="flex" justifyContent="center" gap={1}>
                                    <Box>
                                        <option.icon color={color}></option.icon>
                                    </Box>
                                    <Box>
                                        <Typography color={color}>
                                            {option.display}
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        );
                    })}
                </List>
            </div>
            <scrollContext.Provider value={{topScroll}}>
                <div 
                    ref={refContent as any}
                    style={{
                        overflow: "auto",
                        width: "100%"
                    }}
                >
                    <Outlet></Outlet>
                </div>
            </scrollContext.Provider>
        </div>
    )
}