import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Modal, Card, CardHeader, Divider, Button, TextField, Box } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import PortalComponent from "./portal.component";
import { Close } from "@mui/icons-material";
// import Close from "@mui/icons-material/Close";
// import Swal from "sweetalert2";

/**
 * Interface de los accesorios del componente
 */
interface IVarsComponentProps {
    /**
     * Variables a renderizar
     */
    vars: {[key: string]: any},

    /**
     * Permite disparar un evento cuando se desea editar una variable
     * @param name Nombre de variable
     */
    onSave?(name: string, value: string, reopen: () => void): void;
}

/**
 * Definir componente para manejar una lista de variables 
 * @param props Accesorios del componente
 * @returns Componente renderizado
 */
export default function VarsComponent(props: IVarsComponentProps) {
    const [openEditModal, setOpenEditModal] = React.useState<string | null>(null);
    const [textEditModal, setTextEditModal] = React.useState<string>("");

    // Lista de variables en forma de array
    let vars: {name: string, value: string}[] = [];

    // Realizar el calculo para extraer las variables
    for(let key in props.vars) {
        let value: string;

        try {
            // Validar si la variable actual se trata de un objeto
            if(props.vars[key] instanceof Object) {
                // Transformar a un formato más accesible
                value = JSON.stringify(props.vars[key], null, '  ');
            }
            else throw new Error("No es un objeto");
        }
        catch(err) {
            value = String(props.vars[key]);
        }
        
        // Agregar valor a la lista
        vars.push({
            name: key,
            value: value
        });
    }

    // Ordenar lista de variables
    vars = vars.sort();

    /**
     * Cerrar modal
     */
    function CloseModal() {
        setOpenEditModal(null);
    }

    /**
     * Permite abrir el modal para editar la variable
     * @param name Nombre de variable a editar
     */
    function OnEdit(name: string) {
        setOpenEditModal(name);

        try {
            setTextEditModal(typeof props.vars[name] === "string" ? String(props.vars[name]) : JSON.stringify(props.vars[name], null, '  '));
        }
        catch(err) {
            setTextEditModal(String( props.vars[name]));
        }
    }

    function onSave(name: string, value: any) {
        return () => {
            setOpenEditModal(null);

            props.onSave && props.onSave(name, value, () => {
                setOpenEditModal(name);
            })
        };
    }
    
    // Renderizar el componente
    return (
        <Box 
            className="scroll-styled"
            sx={{
                height: "100%",
                overflowY: "auto",
                overflowX: "visible",
                width: "100%"
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Valor</TableCell>
                        {props.onSave && <TableCell align="right">Acciones</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vars.map(itemVar => (
                        <TableRow key={itemVar.name}>
                            <TableCell sx={{py: 0, fontSize: "8px !important"}}>{itemVar.name}</TableCell>
                            <TableCell sx={{py: 0, fontSize: "8px !important"}}><pre>{itemVar.value}</pre></TableCell>
                            {props.onSave && (
                                <TableCell sx={{py: 0, }} align="center">
                                    <>
                                        <IconButton onClick={() => OnEdit(itemVar.name)}>
                                            <Edit></Edit>
                                        </IconButton>
                                    </>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <PortalComponent>
                <Modal open={!!openEditModal} onClose={CloseModal} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Card sx={{width: "100%"}}>
                        <CardHeader title={"Editar propiedad"} action={<IconButton onClick={CloseModal}><Close color="error"></Close></IconButton>}></CardHeader>
                        <Divider></Divider>
                        <Box p={2}>
                            <TextField multiline rows={8} value={textEditModal} onChange={(ev) => setTextEditModal(ev.target.value)} fullWidth></TextField>
                        </Box>
                        <Box p={1}>
                            <Button variant="contained" fullWidth disabled={!textEditModal.length} onClick={onSave(openEditModal!, textEditModal)}>
                                Guardar
                            </Button>
                        </Box>
                    </Card>
                </Modal>
            </PortalComponent>
        </Box>
    );
}