/** Variable receptora de los environments */
let environments: {
    production: boolean,
    server: string,
    wsserver: string
};

/**
 * Validar si se encuentra en modo de desarrollo
 */
if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // ! Environments of development
    environments = {
        server: "http://localhost:9012",
        wsserver: "ws://localhost:9012/chat/", 
        production: false,
        // server: "https://apicontratos.fibextelecom.net/",
        // wsserver: "wss://apicontratos.fibextelecom.net/chat/", 
    };
}
else {
    // ! Environments of production
    environments = {
        production: true,
        server: "https://apicontratos.fibextelecom.net/",
        wsserver: "wss://apicontratos.fibextelecom.net/chat/", 
    };
}

export default environments;