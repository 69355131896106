import React from "react";
import { IChatInfoBot, IMessage } from "../../../models/whatsapp_venta";
import axios from "axios";
import urlJoin from "url-join";
import environments from "../../../environments/environments";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import ChatComponent from "../../../components/chat.component";
import VarsComponent from "../../../components/vars.component";
import Swal from "sweetalert2";
import ButtonAppBar from "../../../components/app.component";

/**
 * Componente para manejar un simple chat
 * @returns componente renderizado
 */
export default function ManageChatApp() {
    const navigate = useNavigate();
    const { id_client, id_chat } = useParams();
    const [chat, setChat] = React.useState<IChatInfoBot | null>(null);

    React.useEffect(() => {
        if(chat) {
            let socket: WebSocket | undefined;
            let timer: NodeJS.Timer | undefined;
    
            const init = () => {
                if(environments.production) {
                    const url = new URL("/chat/" + encodeURIComponent(chat.id_chat || ""), environments.wsserver);
                    url.searchParams.set("no_init", "true");
    
                    socket = new WebSocket(url.href);
                    
                    socket.onopen = (ev) => {
                        console.log("Conexión establecida con Abby correctamente...");
                    }
                    socket.onclose = (ev) => {
                        if(socket) {
                            socket = undefined;
                            console.log("Se ha perdido la conexión inesperadamente, intentando conectarse...");
                            timer = setTimeout(() => init(), 5000);
                          }
                          else {
                            console.log("Se ha cerrado la conexión con abby")
                        }
                    } 
                    socket.onerror = (ev) => {
                        console.log("Hubo un error durante la conexión");
                    }
                    socket.onmessage = (ev) => {
                        const msg = JSON.parse(ev.data);
    
                        if(msg.event === "message") {
                            msg.data.id = msg.data.id + "_" + Date.now();
                            // msg.data.from_me = !msg.data.from_me;
    
                            const msgItem: IMessage = msg.data;
                            setChat((chat) => {
                                if(chat) {
                                    return {
                                        ...chat,
                                        messages: [...chat.messages, msgItem]
                                    }
                                }
                                return null;
                            })
                          // this.messages.push(msgItem);
                          // console.log(msg.data);
                        }
                        // this.messages.
                        console.log(msg);
                    }
                }
            }
            init();
            
            return () => {
                try {
                    if(timer) clearTimeout(timer);
                    if(socket) {
                        socket.close();
                        socket = undefined;
                    }
                }
                catch(err) { }
            }
        }
    }, [id_chat, !!chat]);

    /**
     * Efecto cuando inicia el componente
     */
    // const [chat, setChat] = React.useState<IChatBot>({});
    React.useEffect(() => {
        const abort = new AbortController();

        axios.get(urlJoin(environments.server, "clients", encodeURIComponent(id_client || ""), encodeURIComponent(id_chat || "")), { 
            headers: {
                Authorization: localStorage.getItem("authtoken")
            },
            signal: abort.signal
        })
            .then((res) => {
                if(res.status == 200) {
                    console.log(res.data);
                    setChat((chats) => {
                        res.data.messages = res.data.messages.map((item: any) => {
                            return {
                                ...item,
                                from_me: !item.from_me,
                            }
                        });
                        
                        if(chats) {
                            return {
                                ...res.data,
                                messages: [
                                    ...res.data.messages, 
                                    ...chats.messages
                                ]
                            }
                        }
                        return res.data;
                    });
                }
                else throw new Error("Hubo un error al intentar obtener el chat");
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            });
        
        return () => abort.abort();
    }, []);


    // Determinar si se ha cargado el chat
    if(chat) {
        /**
         * Permite actualizar el valor de la variable
         * @param name nombre de variable
         * @param value valor de variable
         * @param reopen Reabrir modal de edición (usualmente utilizado luego de haber informado que hubo un error)
         */
        const OnSave = (name: string, value: string, reopen: () => void) => {
            Swal.fire({
                title: "Enviando",
                text: "Espere un poco"
            });
            Swal.showLoading();
            
            try {
                const valueData = typeof chat.vars[name] !== "string" ? JSON.parse(value) : value;
                
                axios.put(urlJoin(environments.server, "clients", encodeURIComponent(id_client || ""), encodeURIComponent(id_chat || ""), "vars"), { 
                    vars: { [name]: valueData } 
                }, { 
                    headers: {
                        Authorization: localStorage.getItem("authtoken")
                    } 
                })
                    .then(() => {
                        setChat({
                            ...chat,
                            vars: {
                                ...chat.vars, 
                                [name]: valueData
                            }
                        });

                        console.log(name, valueData);
                        console.log("POTATO:::", {
                            ...chat,
                            vars: {
                                ...chat.vars, 
                                [name]: valueData
                            }
                        });
                        
                        Swal.fire({
                            icon: "success",
                            title: "Correcto",
                            text: "Se ha actualizado correctamente"
                        });
                    })
                    .catch((err) => {
                        Swal.fire({
                            title: "Error",
                            icon: "error",
                            text: "Hubo un error al intentar actualizar la variable",
                            footer: "Lamentamos los inconvenientes, intentelo de nuevo"
                        })
                            .then(() => {
                                reopen();
                            })
                    })
            }
            catch(err) {
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: "Hubo un error al intentar actualizar la variable",
                    footer: "Lamentamos los inconvenientes, intentelo de nuevo"
                })
                    .then(() => {
                        reopen();
                    })
            }
        }
        
        return (
            <Box 
                display="flex"
                flexDirection="column"
                sx={{
                    height: "100%",
                }}
            >
                <ButtonAppBar back={() => navigate(urlJoin("/client", String(id_client)))}></ButtonAppBar>
                <Box 
                    sx={{
                        display: "flex", 
                        top: "0", 
                        left: "0", 
                        width: "100%", 
                        height: "100%", 
                        overflow: "auto"
                    }}
                >
                    <ChatComponent messages={chat.messages}></ChatComponent>
                    <VarsComponent vars={chat.vars}></VarsComponent>
                </Box>
            </Box>
        )
    }
    return <CircularProgress></CircularProgress>
}