import React from "react";
import { Box, Button, Card, CardHeader, Dialog, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import ButtonAppBar from "../../components/app.component";
import axios from "axios";
import environments from "../../environments/environments";
import Swal from "sweetalert2";
import { Close, Delete, Edit } from "@mui/icons-material";

interface IPromo {
    id_servicio: string,
    title: string,
    description: string,
    dollar_amount_per_period: number[],
    dollar_amount: number,
    citys: "*"
}

interface EditPromocionProps {
    onClose: () => void,
    open: boolean,
    title: string,
    mount: number
}

function EditPromocion({
    onClose, 
    open,
    title,
    mount
}: EditPromocionProps) {
    const [localTitle, setLocalTitle] = React.useState<string>(title);
    const [localMount, setLocalMount] = React.useState<number>(mount);
    
    return (
        <Dialog onClose={onClose} open={!!open}>
            {/* <DialogTitle>Modificar promoción #{(idToModify || 0)+1}</DialogTitle> */}
            <CardHeader 
                title="Modificar promoción"
                action={
                    <Tooltip title="Cerrar">
                        <IconButton color="error" onClick={onClose}>
                            <Close></Close>
                        </IconButton>
                    </Tooltip>
                }
            ></CardHeader>
            <List>
                <ListItem>
                    <TextField
                        size="small"
                        value={localTitle}
                        onChange={(ev) => setLocalTitle(ev.target.value)}
                        label="Nombre promoción"
                        placeholder="Ejemplo: Combo navideño 50$"
                        fullWidth
                        ></TextField>
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Amount</InputLabel>
                        <OutlinedInput
                            type="number"
                            value={String(localMount)}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Precio dolar"
                            size="small"
                            onChange={(ev) => setLocalMount(Number(ev.target.value))}
                            fullWidth
                        />
                    </FormControl>
                </ListItem>
                <ListItem>
                    <Button 
                        variant="contained"
                        fullWidth
                    >
                        Guardar
                    </Button>
                </ListItem>
            </List>
        </Dialog>
    )
}

export default function PromocionesApp() {
    const [promociones, setPromociones] = React.useState<IPromo[]>([]);
    const [modified, setModified] = React.useState(false);
    const [idToModify, setIdToModify] = React.useState<number | undefined>();

    const modify = idToModify !== undefined ? promociones[idToModify] : undefined;
    
    React.useEffect(() => {
        const abort = new AbortController();

        axios.get(new URL("/promociones", environments.server).href, { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                setModified(false);
                setPromociones(res.data);
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            });

        return () => abort.abort();
    }, []);

    function savePromociones() {
        Swal.fire({
            title: "Actualizando",
            text: "espere un poco",
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        
        axios.put(new URL("/promociones", environments.server).href, { promociones }, { headers: {Authorization: localStorage.getItem("authtoken")} })
            .then((res) => {
                setModified(false);
                Swal.fire({
                    icon: "success",
                    title: "Correcto",
                    text: "Se ha actualizado correctamente"
                });
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se logró obtener la configuración actual de los planes",
                    footer: `Motivo: ${err instanceof Error ? err.message : "Desconocido"}`
                });
            });
    }

    function handlerClose() {
        setIdToModify(undefined);
    }

    function handlerAddPromo() {
        setPromociones((p) => [
            ...p, 
            {
                title: "Nueva promoción",
                description: "Sin descripción",
                dollar_amount: 40,
                citys: "*",
                dollar_amount_per_period: [],
                id_servicio: ""
            }
        ]);
        setModified(true);
    }

    function handlerDelete(index: number) {
        Swal.fire({
            icon: "warning",
            title: "Confirmación",
            text: "¿Estás seguro que deseas eliminar esta promoción?",
            footer: "Esta acción no se puede revertir"
        })
            .then((res) => {
                if(res.isConfirmed) {
                    console.log("DELETED:", index)
                    setPromociones((p) => {
                        const newPromo = [...p];
                        newPromo.splice(index, 1);
                        return newPromo;
                    });
                    setModified(true);
                }
            })
    }
    
    return (
        <Box>
            <ButtonAppBar></ButtonAppBar>
            <Box p={2}>
                <Card>
                    <CardHeader 
                        title="Promociones"
                        action={
                            <Button 
                                variant="contained"
                                disabled={!modified}
                                onClick={savePromociones}
                            >
                                Guardar
                            </Button>
                        }
                    ></CardHeader>
                    <Divider></Divider>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Nombre promoción</b>
                                </TableCell>
                                <TableCell>
                                    <b>Descripción</b>
                                </TableCell>
                                <TableCell>
                                    <b>Monto Promoción</b>
                                </TableCell>
                                <TableCell width={1}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {promociones.map((promo, index) => (
                                <TableRow key={index}>
                                    <TableCell size="small">{promo.title}</TableCell>
                                    <TableCell size="small">{promo.description}</TableCell>
                                    <TableCell size="small">$ {promo.dollar_amount}</TableCell>
                                    <TableCell size="small">
                                        <Box display="flex" gap={1}>
                                            <Tooltip title="Editar">
                                                <IconButton size="small" onClick={() => setIdToModify(index)}>
                                                    <Edit fontSize="small"></Edit>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Eliminar">
                                                <IconButton size="small" onClick={() => handlerDelete(index)}>
                                                    <Delete fontSize="small"></Delete>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Button 
                                        variant="outlined"
                                        fullWidth
                                        onClick={handlerAddPromo}
                                    >
                                            Agregar
                                        </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Box>
            {modify && (
                <EditPromocion 
                    open={!!modify} 
                    onClose={handlerClose}
                    title={modify.title}
                    mount={modify.dollar_amount}
                ></EditPromocion>
            )}
        </Box>
    )
}