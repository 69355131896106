import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import useUser from '../hooks/useUser.hook';
import { useNavigate } from 'react-router-dom';

export default function ButtonAppBar(props: { back?: () => void }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const user = useUser();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem("authtoken");
    document.cookie = "Authorization=";
    navigate("/login");
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{backgroundColor: "black"}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Aplicación
          </Typography>
          {props.back && (
            <Button color="inherit">
              <ArrowBackIcon onClick={props.back}></ArrowBackIcon>
            </Button>
          )}
          <div>
              <Button
                variant='outlined'
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {user.username}
                <AccountCircle />
              </Button>
              
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem disabled>{user.username} {"<" + user.email + ">"}</MenuItem> */}
                <MenuItem onClick={handleClose}>
                  <Box display="flex" justifyContent="center" alignContent="center" gap={1}>
                    <ExitToApp fontSize='small'></ExitToApp> Cerrar sessión
                  </Box>
                </MenuItem>
              </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}