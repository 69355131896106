import React from "react";
import TableComponent from "../../../components/table.component";
import { Table, TableHead, TableBody, TableCell, TableRow, Avatar, Button, Grid, Box, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { IChatBot } from "../../../models/whatsapp_venta";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import environments from "../../../environments/environments";
import urlJoin from "url-join";
import PortalComponent from "../../../components/portal.component";
import ButtonAppBar from "../../../components/app.component";

// const headerData = [
//     {
//         key: "name",
//         title: "Nombre"
//     }
// ];

/**
 * Permite manejar una lista de chats
 * @returns componente renderizado
 */
export default function ManageChatsApp() {
    const [chats, setChats] = React.useState<IChatBot[]>([]);
    const { id_client } = useParams();
    const navigate = useNavigate();

    const [fNombre, setFNombre] = React.useState("");
    const [fCedula, setFCedula] = React.useState("");
    const [fPagado, setFPagado] = React.useState("");
    const [fContratoGenerado, setFContratoGenerado] = React.useState("");
    const [fNroRef, setFNroRef] = React.useState("");
    const [fCountMsg, setFCountMsg] = React.useState("");
    
    // Filtrar
    const chatsFiltered = React.useMemo(() => (
        chats.filter(h => (
            (!fNombre || h.name?.toLocaleLowerCase().includes(fNombre.toLocaleLowerCase())) && 
            (!fCedula || h.cedula_identidad?.toLocaleLowerCase().includes(fCedula.toLocaleLowerCase())) && 
            (!fPagado || (h.referencia ? "SI" : "NO").toLocaleLowerCase().includes(fPagado.toLocaleLowerCase())) && 
            (!fContratoGenerado || (h.contrato ? "SI" : "NO").toLocaleLowerCase().includes(fContratoGenerado.toLocaleLowerCase())) && 
            (!fNroRef || h.referencia?.toLocaleLowerCase().includes(fNroRef.toLocaleLowerCase())) && 
            (!fCountMsg || String(h.cantidadMSG).toLocaleLowerCase().includes(fCountMsg.toLocaleLowerCase()))
        ))
    ), [chats, fNombre, fCedula, fPagado, fContratoGenerado, fNroRef, fCountMsg]);
    
    /**
     * Efecto al inicializar el componente
     */
    React.useEffect(() => {
        const abort = new AbortController();

        axios.get<IChatBot[]>(urlJoin(environments.server, "clients", encodeURIComponent(id_client || "")), { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then(async (res) => {
                if(res.status == 200) {
                    const result = await Promise.all(res.data.map(async (chat): Promise<IChatBot> => {
                        const res = await axios.get(urlJoin(environments.server, "clients", encodeURIComponent(id_client || ""), encodeURIComponent(chat.id_chat || "")), { 
                            headers: {
                                Authorization: localStorage.getItem("authtoken")
                            },
                            signal: abort.signal
                        });

                        return {
                            ...res.data, 
                            ...chat,
                            name: chat.name || res.data.vars["NOMBRE_USUARIO"],
                            cedula_identidad: res.data.vars["CEDULA_IDENTIDAD"],
                            pagado: !!res.data.vars["NUMERO_REFERENCIA"],
                            contrato: !!res.data.vars["URL_CONTRATO"],
                            referencia: res.data.vars["NUMERO_REFERENCIA"],
                        };
                    }));

                    console.log("result::::", result);
                    
                    setChats(result);
                }
                else throw new Error("No se logró obtener los chats del cliente " + id_client);
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            })
        
        return () => abort.abort();
    }, []);
    
    return (
        <>
            <ButtonAppBar back={() => navigate(urlJoin("/"))}></ButtonAppBar>
            <Grid container>
                <Grid item xs={9}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head"><b>Avatar</b></TableCell>
                                <TableCell variant="head"><b>Nombre</b></TableCell>
                                <TableCell variant="head"><b>Cedula</b></TableCell>
                                <TableCell variant="head"><b>Pagado</b></TableCell>
                                <TableCell variant="head"><b>Contrato generado</b></TableCell>
                                <TableCell variant="head"><b>Nro. Referencia</b></TableCell>
                                <TableCell variant="head" align="right"><b>Acciones</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chatsFiltered.map(chatItem => (
                                <TableRow key={chatItem.id_chat}>
                                    <TableCell>
                                        <Avatar src={chatItem.picture}></Avatar>
                                    </TableCell>
                                    <TableCell>{chatItem.name || "Sin nombre"}</TableCell>
                                    <TableCell>{chatItem.cedula_identidad}</TableCell>
                                    <TableCell>{chatItem.pagado ? "SI" : "NO"}</TableCell>
                                    <TableCell>{chatItem.contrato ? "SI" : "NO"}</TableCell>
                                    <TableCell>{chatItem.referencia}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => {
                                            navigate("/client/" + chatItem.id_client + "/chat/" + encodeURIComponent(chatItem.id_chat));
                                        }}>Administrar</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>    
                <Grid item xs={3}>
                <Box sx={{position: "sticky", top: "0"}}>
                            <CardHeader title="Filtrar"></CardHeader>
                            <Box p={1} display="flex" flexDirection="column" gap={1.5}>
                                <TextField
                                    label="Nombre"
                                    size="small"
                                    onChange={(ev) => setFNombre(ev.target.value)}
                                ></TextField>
                                <TextField
                                    label="Cédula"
                                    size="small"
                                    onChange={(ev) => setFCedula(ev.target.value)}
                                    ></TextField>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Pagado</InputLabel>
                                    <Select
                                        value={fPagado}
                                        label="Pagado"
                                        onChange={(e) => setFPagado(e.target.value)}
                                        size="small"
                                    >
                                        <MenuItem value="">Ninguno</MenuItem>
                                        <MenuItem value="SI">SI</MenuItem>
                                        <MenuItem value="NO">NO</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Contrato generado</InputLabel>
                                    <Select
                                        value={fContratoGenerado}
                                        label="Contrato generado"
                                        onChange={(e) => setFContratoGenerado(e.target.value)}
                                        size="small"
                                    >
                                        <MenuItem value="">Ninguno</MenuItem>
                                        <MenuItem value="SI">SI</MenuItem>
                                        <MenuItem value="NO">NO</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Nro. Ref"
                                    size="small"
                                    onChange={(ev) => setFNroRef(ev.target.value)}
                                    ></TextField>
                                <TextField
                                    label="Candidad Mensajes"
                                    size="small"
                                    onChange={(ev) => setFCountMsg(ev.target.value)}
                                    ></TextField>
                            </Box>
                        </Box>
                </Grid>
            </Grid>
        </>
    )
}