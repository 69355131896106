import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ButtonAppBar from "../../components/app.component";
import React from "react";
import axios from "axios";
import environments from "../../environments/environments";
import Swal from "sweetalert2";

interface IFranquicia {
    nombre_esta: string,
    nombre_ciudad: string,
    nombre_franq: string,
    id_franq: string,
    id_ciudad: string,
    id_esta: string
}

interface ICiudad {
    id_esta:        string,
    nombre_esta:    string,
    // status_esta:    string,
    // id_pais:        string,
    codigo_esta:    string
}

interface ICiudadesProps {
    name?: string
    value?: string
    onChange?: (value: string) => void
}

function CiudadesComponent({
    name,
    value,
    onChange
}: ICiudadesProps) {
    const [ciudades, setCiudades] = React.useState<ICiudad[]>([]);
    
    React.useEffect(() => {
        const abort = new AbortController();
        
        /**
         * Obtener todas las franquicias
         */
        axios.get<ICiudad[]>(new URL("/franquicias/estado", environments.server).href, { 
            headers: {Authorization: localStorage.getItem("authtoken")},
            signal: abort.signal
        })
            .then((res) => {
                if(res.status == 200) {
                    setCiudades(res.data);
                }
                else {
                    throw new Error("Hubo un error al intentar obtener las franquicias");
                }
            })
            .catch((err) => {
                if(!abort.signal.aborted) {
                    console.error(err);
                }
            })
        
        return () => abort.abort();
    }, []);
    
    return (
        <FormControl fullWidth>
            <FormControl fullWidth>
            <InputLabel>Ciudades</InputLabel>
            <Select
                fullWidth
                name={name}
                value={value || ''}
                label="Ciudades"
                onChange={onChange && ((ev) => onChange(ev.target.value as string))}
            >
                {ciudades.map(esta => (
                    <MenuItem
                        key={esta.id_esta} 
                        value={esta.id_esta}
                    >
                        {esta.nombre_esta}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        </FormControl>
    )
}

interface IFranquiciasProps {
    id_esta?: string
    values: {id_ciudad: string, id_franq: string}[],
    onChange?: (id_ciudad: string, id_franq: string) => void
}

function FranquiciasComponent({
    id_esta,
    values,
    onChange
}: IFranquiciasProps) {
    const [franquicias, setFranquicias] = React.useState<IFranquicia[]>([]);

    React.useEffect(() => {
        if(id_esta) {
            const abort = new AbortController();
            
            /**
             * Obtener todas las franquicias
             */
            axios.get<IFranquicia[]>(new URL("/franquicias/estado/" + encodeURIComponent(id_esta), environments.server).href, { 
                headers: {Authorization: localStorage.getItem("authtoken")},
                signal: abort.signal
            })
                .then((res) => {
                    if(res.status == 200) {
                        setFranquicias(res.data.sort((a, b) => a.nombre_ciudad > b.nombre_ciudad ? 1 : -1));
                    }
                    else {
                        throw new Error("Hubo un error al intentar obtener las franquicias");
                    }
                })
                .catch((err) => {
                    if(!abort.signal.aborted) {
                        console.error(err);
                    }
                })
            
            return () => abort.abort();
        }
    }, [id_esta]);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: "2%"}}>Habilitar</TableCell>
                        {/* <TableCell>Nombre Estado</TableCell> */}
                        <TableCell>Nombre Ciudad</TableCell>
                        <TableCell>Nombre Franquicia</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {franquicias.map(franq => (
                        <TableRow key={franq.id_franq + "::" + franq.id_ciudad + "::" + franq.id_esta}>
                            <TableCell>
                                <Checkbox
                                    checked={values.some(v => v.id_franq === franq.id_franq && v.id_ciudad === franq.id_ciudad)}
                                    onClick={onChange && (() => onChange(franq.id_ciudad, franq.id_franq))}
                                ></Checkbox>
                            </TableCell>
                            {/* <TableCell>{franq.nombre_esta}</TableCell> */}
                            <TableCell>{franq.nombre_ciudad}</TableCell>
                            <TableCell>{franq.nombre_franq}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {!franquicias.length && (
                <Box py={8} textAlign="center" sx={{
                    border: "1px solid #ccc", 
                    borderRadius: "10px", 
                    mt: "10px",
                    color: "#999"
                }}>
                    No existe ninguna franquicia para seleccionar
                </Box>
            )}
        </>
    )
}

export default function FranquiciaApp() {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isModified, setIsModified] = React.useState<boolean>(false);
    const [idEstado, setIdEstado] = React.useState<string | undefined>();
    const [activeFranquicias, setActiveFranquicias] = React.useState<{id_franq: string, id_ciudad: string}[]>([]);
    
    async function saveActivates() {
        Swal.fire({
            title: "Guardando",
            text: "Estamos guardando los cambios",
            footer: "Espere unos momentos",
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        Swal.showLoading();
        
        axios.put(new URL("franquicias/estado/" + encodeURIComponent(idEstado || "") + "/activates", environments.server).href, { activates: activeFranquicias }, { headers: {Authorization: localStorage.getItem("authtoken")} })
            .then((res) => {
                if(res.status == 200) {
                    setIsModified(false);
                    Swal.fire({
                        icon: "success",
                        title: "Correcto",
                        text: "Se ha actualizado la configuración",
                        footer: "Puedes cerrar este cuadro de diálogo"
                    });
                }
                else {
                    throw new Error("Hubo un error en la petición");
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se logró actualizar la configuración de los planes",
                    footer: `Motivo: ${err instanceof Error ? err.message : "Desconocido"}`
                });
            })
    }
    
    function handlerFranquicias(id_ciudad: string, id_franq: string) {
        setIsModified(true);
        setActiveFranquicias((actives) => {
            const index = actives.findIndex(v => v.id_ciudad === id_ciudad && v.id_franq === id_franq);
            if(index !== -1) {
                const newActives = [...actives];
                newActives.splice(index, 1);
                return newActives;
            }
            return [...actives, {id_ciudad, id_franq}];
        });
    }

    async function handlerChangeCiudad(id_esta: string) {
        if(isModified) {
            const res = await Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "No has guardado los cambios. ¿Te gustaría guardar los cambios efectuados en la franquicia actual?",
                confirmButtonText: "Guardar",
                cancelButtonText: "No guardar",
                showCancelButton: true,
            });

            if(res.isConfirmed) {
                await saveActivates();
            }
            else {
                if(res.dismiss === Swal.DismissReason.backdrop) return;
            }
        }

        setIsLoading(true);
        setIsModified(false);
        setIdEstado(id_esta);

        axios.get(new URL("franquicias/estado/" + encodeURIComponent(id_esta || "") + "/activates", environments.server).href, { headers: {Authorization: localStorage.getItem("authtoken")} })
            .then((res) => {
                if(res.status == 200) {
                    setIsLoading(false);
                    setActiveFranquicias(res.data);
                }
                else throw new Error("Hubo un error durante la petición");
            })
            .catch((err) => {
                setIsLoading(false);
                setActiveFranquicias([]);
                console.error(err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No se logró obtener la configuración actual de los planes",
                    footer: `Motivo: ${err instanceof Error ? err.message : "Desconocido"}`
                });
            });
    }
    
    return (
        <>
            <ButtonAppBar></ButtonAppBar>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box p={2} my={1} sx={{background: "#eeeeee", borderRadius: "5px"}}>
                            <Typography sx={{color: "#444"}}>
                                Este apartado permite ayudar al sistema del bot a determinar las franquicias que pertenece a cada ciudad, de manera
                                que al momento de mostrar los planes tengan una relación completa.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap={1}>
                            <CiudadesComponent
                                name="ciudades"
                                onChange={handlerChangeCiudad}
                                value={idEstado}
                            />
                            <Button
                                variant="contained"
                                disabled={!isModified}
                                onClick={saveActivates}
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FranquiciasComponent 
                            id_esta={idEstado} 
                            values={activeFranquicias}
                            onChange={handlerFranquicias}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}